import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {EStorageKey} from 'src/app/shared/enums/storage-key.enum';
import {catchError, from, of} from 'rxjs';
import {Preferences} from '@capacitor/preferences';
import { Device } from '@capacitor/device';

@Injectable({providedIn: 'root'})
export class LocaleService {

  constructor(
    private translateService: TranslateService
  ) {
  }

  setupLanguages(): void {
    this.translateService.addLangs(['en', 'da']);
    this.translateService.setDefaultLang('en');

    from(Preferences.get({key: EStorageKey.Language})).pipe(
      catchError(() => of(this.translateService.defaultLang)),
    ).subscribe(async (storedLanguage: any) => {

      if (storedLanguage.value && storedLanguage.value.match(/en|da/)) {
        this.registerLanguageAndCulture(storedLanguage.value);
      }
      else
      {
        const deviceLanguage = (await Device.getLanguageCode()).value;
        if (deviceLanguage.match(/en|da/)) {
          this.registerLanguageAndCulture(deviceLanguage);
        } else {
          this.registerLanguageAndCulture(this.translateService.defaultLang);
        }
      }
    });
  }

  setLanguage(language: string): void {
    this.registerLanguageAndCulture(language);
  }


  private registerLanguageAndCulture(language: string): void {
    this.translateService.use(language);
    Preferences.set({key: EStorageKey.Language, value: language}).then(value => {
    });
  }
}
